import React, { useContext } from 'react';
import FadeIn from 'react-fade-in';

import { GlobalContext } from '../../context/GlobalState';
import { clearScore, setShowMenu } from '../../context/GlobalActions';

import './Score.scss';

const Score = () => {
  const { score, dispatch, showMenu } = useContext(GlobalContext);

  const handleClick = () => {
    if (showMenu !== true) {
      dispatch(setShowMenu());
    }
  };

  return (
    <div className="score">
      <FadeIn>
        <h2 className="score-h2">SCORE</h2>
        <span className="score-total">{score}</span>
        <span className="score-options" onClick={handleClick}>
          MAIN MENU
        </span>
        <span className="score-options" onClick={() => dispatch(clearScore())}>
          CLEAR SCORE
        </span>
      </FadeIn>
    </div>
  );
};

export default Score;
