import React from 'react';

import RuleItem from '../RuleItem/RuleItem';

import './Rules.scss';

const Rules = ({ handleRules, toggleRules }) => {
  return (
    <section
      className="rules"
      style={{
        animation: `${toggleRules ? 'fadeIn' : 'fadeOut'} 1s`
      }}
      onClick={handleRules}
    >
      <RuleItem />
    </section>
  );
};

export default Rules;
