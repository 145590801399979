import React, { createContext, useEffect, useReducer } from 'react';
import AppReducer from './AppReducer';
import { setScore } from './GlobalActions';

const initialState = {
  alert: null,
  score: 0,
  playerOneScore: 0,
  playerTwoScore: 0,
  showMenu: true,
  showSinglePlayer: false,
  showMultiPlayer: false,
  showMultiPlayerWait: false,
  showMultiPlayerMoves: false,
  showDecisionWaitingRoom: false,
  showDecisionMultiPlayer: false
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const {
    alert,
    score,
    playerOneScore,
    playerTwoScore,
    multiScore,
    showMenu,
    showSinglePlayer,
    showMultiPlayer,
    showMultiPlayerWait,
    showMultiPlayerMoves,
    showDecisionWaitingRoom,
    showDecisionMultiPlayer
  } = state;

  // Get score from local storage if available.
  useEffect(() => {
    if (localStorage.getItem('score')) {
      dispatch(setScore(parseInt(localStorage.getItem('score'))));
    }
  }, []);

  // Adds score to local storage if score is updated.
  useEffect(() => {
    localStorage.setItem('score', score);
  }, [score]);

  return (
    <GlobalContext.Provider
      value={{
        alert,
        score,
        playerOneScore,
        playerTwoScore,
        multiScore,
        dispatch,
        showMenu,
        showSinglePlayer,
        showMultiPlayer,
        showMultiPlayerWait,
        showMultiPlayerMoves,
        showDecisionWaitingRoom,
        showDecisionMultiPlayer
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
