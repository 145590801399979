import React from 'react';

import MoveIcon from '../MoveIcon/MoveIcon';

import './MoveItem.scss';

const MoveItem = ({ move, getUserGuess }) => {
  return (
    <div className={`move-item ${move}`} onClick={() => getUserGuess(move)}>
      <MoveIcon move={move} />
    </div>
  );
};

export default MoveItem;
