import React from 'react';

import MoveIcon from '../MoveIcon/MoveIcon';

import './DecisionItem.scss';

const DecisionItem = ({ guess }) => {
  return (
    <div className="decision-item">
      <MoveIcon move={guess} />
    </div>
  );
};

export default DecisionItem;
