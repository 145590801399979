import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import DecisionItem from '../DecisionItem/DecisionItem';

import './Decisions.scss';

const Decisions = ({ resetGame, userGuess, houseGuess, decision }) => {
  return (
    <section className="decisions">
      <div className="decision">
        <h3>You</h3>
        <DecisionItem guess={userGuess} />
      </div>
      <div className="decision middle">
        <h3>{decision}</h3>
        <CustomButton onClick={resetGame}>Play Again</CustomButton>
      </div>
      <div className="decision">
        <h3>House</h3>
        <DecisionItem guess={houseGuess} />
      </div>
    </section>
  );
};

export default Decisions;
