export const incrementScore = () => ({
  type: 'INCREMENT_SCORE'
});

export const decrementScore = () => ({
  type: 'DECREMENT_SCORE'
});

export const clearScore = () => ({
  type: 'CLEAR_SCORE'
});

export const setScore = score => ({
  type: 'SET_SCORE',
  payload: score
});

export const setShowMenu = () => ({
  type: 'SHOW_MENU'
});

export const setShowSinglePlayer = () => ({
  type: 'SHOW_SINGLE_PLAYER'
});

export const setShowMultiPlayer = () => ({
  type: 'SHOW_MULTI_PLAYER'
});

export const setShowMultiPlayerWait = () => ({
  type: 'SHOW_MULTI_PLAYER_WAIT'
});

export const setShowMultiPlayerMoves = () => ({
  type: 'SHOW_MULTI_PLAYER_MOVES'
});

export const setResetGame = () => ({
  type: 'RESET_GAME'
});

export const setAlert = alert => ({
  type: 'SET_ALERT',
  payload: alert
});

export const removeAlert = () => ({
  type: 'REMOVE_ALERT'
});

export const setShowDecisionWaitingRoom = () => ({
  type: 'SHOW_DECISION_WAITING_ROOM'
});
export const setShowDecisionMultiPlayer = () => ({
  type: 'SHOW_DECISION_MULTI_PLAYER'
});

export const incrementPlayerOneScore = () => ({
  type: 'INCREMENT_PLAYER_ONE_SCORE'
});

export const incrementPlayerTwoScore = () => ({
  type: 'INCREMENT_PLAYER_TWO_SCORE'
});

export const clearMultiPlayerScore = () => ({
  type: 'CLEAR_MULTI_PLAYER_SCORE'
});
