import React from 'react';

import '../Score/Score.scss';

const DummyScore = () => {
  return (
    <div className="score dummy">
      <h2 className="score-h2">SCORE</h2>
      <span className="score-total">0</span>
      <span className="score-options">MAIN MENU</span>
      <span className="score-options">CLEAR SCORE</span>
    </div>
  );
};

export default DummyScore;
