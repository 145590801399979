import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import Score from '../Score/Score';
import DummyScore from '../DummyScore/DummyScore';
import Scores from '../Scores/Scores';
import Logo from '../Logo/Logo';

import './Header.scss';

const Header = () => {
  const { showSinglePlayer, multiScore } = useContext(GlobalContext);
  return (
    <header>
      <Logo />
      {showSinglePlayer ? <Score /> : multiScore ? null : <DummyScore />}
      {multiScore ? <Scores /> : null}
    </header>
  );
};

export default Header;
