const outcomes = {
  rock: 'paper',
  paper: 'scissors',
  scissors: 'rock',
};

export const gameDecision = (userGuess, houseGuess) => {
  if (outcomes[userGuess] === houseGuess) {
    return 'You Lose.';
  } else if (userGuess === houseGuess) {
    return 'Draw.';
  } else {
    return 'You Win!';
  }
};

export const gameMultiDecision = (playerOneGuess, playerTwoGuess) => {
  if (outcomes[playerOneGuess] === playerTwoGuess) {
    return {
      winner: 'playerTwo',
      loser: 'playerOne',
      decision: 'Player 2 wins!',
    };
  } else if (playerOneGuess === playerTwoGuess) {
    return { decision: 'Draw!', caption: `Tie.` };
  } else {
    return {
      winner: 'playerOne',
      loser: 'playerTwo',
      decision: 'Player 1 wins!',
    };
  }
};
