import React from 'react';

import './ScoreItem.scss';

const ScoreItem = ({ scoreName, scoreTotal, playerName }) => {
  return (
    <div className="scores-item">
      <h2 className="score-h2">{scoreName}</h2>
      <span className="score-total">{scoreTotal}</span>
      <span className="score-options">{playerName}</span>
    </div>
  );
};

export default ScoreItem;
