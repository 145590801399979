import React from 'react';

import './RuleItem.scss';

const RuleItem = () => {
  return (
    <div className="rule-item">
      <div className="window-close"></div>
      <h3>Rules</h3>
      <div className="rule-item-linebreak"></div>
      <i className="instructions"></i>
    </div>
  );
};

export default RuleItem;
