import React, { useContext } from 'react';
import FadeIn from 'react-fade-in';

import ScoreItem from '../ScoreItem/ScoreItem';

import { GlobalContext } from '../../context/GlobalState';

import './Scores.scss';

const Scores = () => {
  const { playerOneScore, playerTwoScore } = useContext(GlobalContext);
  return (
    <div className="scores">
      <FadeIn>
        <ScoreItem
          scoreName={'score'}
          scoreTotal={playerOneScore}
          playerName={'player 1'}
        />
      </FadeIn>
      <FadeIn>
        <ScoreItem
          scoreName={'score'}
          scoreTotal={playerTwoScore}
          playerName={'player 2'}
        />
      </FadeIn>
    </div>
  );
};

export default Scores;
