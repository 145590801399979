import React from 'react';
import MoveItem from '../MoveItem/MoveItem';

import './Moves.scss';

const Moves = ({ setUserGuessStart, moves }) => {
  const getUserGuess = guess => {
    setUserGuessStart(guess);
  };

  return (
    <section className="moves">
      <h3>Pick a move.</h3>
      <div className="move-items">
        {moves.map((move, index) => (
          <MoveItem key={index} move={move} getUserGuess={getUserGuess} />
        ))}
      </div>
    </section>
  );
};

export default Moves;
