import React from 'react';

import './Footer.scss';

const Footer = ({ children }) => {
  return (
    <footer>
      <span>&copy; 2022 Scissor.Paper.Rock. </span>
      {children}
    </footer>
  );
};

export default Footer;
