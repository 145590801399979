import React, { Fragment, useContext, useEffect } from 'react';

import { GlobalContext } from '../../context/GlobalState';

import { removeAlert } from '../../context/GlobalActions';

import './Alert.scss';

const Alert = () => {
  const { alert, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeAlert());
    }, 1500);
  }, [alert, dispatch]);

  return (
    <Fragment>
      <section
        className="alert-container"
        style={{
          animation: `${alert ? 'fadeIn' : null} .5s`
        }}
      >
        <div className={`alert ${alert.status}`}>
          <div className="alert-content">
            <span className="alert-message">{alert.message}</span>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Alert;
