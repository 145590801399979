const AppReducer = (state, action) => {
  switch (action.type) {
    // Score
    case 'INCREMENT_SCORE':
      return {
        ...state,
        score: state.score + 1
      };
    case 'DECREMENT_SCORE':
      return {
        ...state,
        score: state.score - 1
      };
    case 'CLEAR_SCORE':
      return {
        ...state,
        score: 0
      };
    case 'SET_SCORE':
      return {
        ...state,
        score: action.payload
      };

    // MultiPlayer Score
    case 'INCREMENT_PLAYER_ONE_SCORE':
      return {
        ...state,
        playerOneScore: state.playerOneScore + 1
      };
    case 'INCREMENT_PLAYER_TWO_SCORE':
      return {
        ...state,
        playerTwoScore: state.playerTwoScore + 1
      };
    case 'CLEAR_MULTI_PLAYER_SCORE':
      return {
        ...state,
        playerOneScore: 0,
        playerTwoScore: 0
      };
    // Game
    case 'SHOW_MENU':
      return {
        ...state,
        showMenu: true,
        showSinglePlayer: false,
        showMultiPlayer: false,
        showMultiPlayerWait: false,
        showMultiPlayerMoves: false,
        multiScore: false,
        showDecisionWaitingRoom: false,
        showDecisionMultiPlayer: false
      };
    case 'SHOW_SINGLE_PLAYER':
      return {
        ...state,
        showSinglePlayer: true,
        showMenu: false
      };
    case 'SHOW_MULTI_PLAYER':
      return {
        ...state,
        showMultiPlayer: true,
        showMenu: false,
        showMultiPlayerWait: true,
        showMultiPlayerMoves: false,
        multiScore: false,
        showDecisionWaitingRoom: false,
        showDecisionMultiPlayer: false
      };
    case 'SHOW_MULTI_PLAYER_WAIT':
      return {
        ...state,
        showMultiPlayerWait: true,
        showMultiPlayerMoves: false,
        multiScore: false,
        showDecisionWaitingRoom: false,
        showDecisionMultiPlayer: false
      };
    case 'SHOW_MULTI_PLAYER_MOVES':
      return {
        ...state,
        showMultiPlayerMoves: true,
        showMultiPlayerWait: false,
        multiScore: true,
        showDecisionWaitingRoom: false,
        showDecisionMultiPlayer: false
      };
    case 'SHOW_DECISION_WAITING_ROOM':
      return {
        ...state,
        showDecisionWaitingRoom: true,
        showMultiPlayerMoves: false,
        showDecisionMultiPlayer: false
      };
    case 'SHOW_DECISION_MULTI_PLAYER':
      return {
        ...state,
        showDecisionMultiPlayer: true,
        showDecisionWaitingRoom: false,
        showMultiPlayerMoves: false
      };
    case 'RESET_GAME':
      return {
        ...state,
        showMenu: true,
        showSinglePlayer: false,
        showMultiPlayer: false,
        showMultiPlayerWait: false,
        showMultiPlayerMoves: false,
        showDecisionWaitingRoom: false,
        showDecisionMultiPlayer: false
      };
    // Alerts
    case 'SET_ALERT':
      return {
        ...state,
        alert: action.payload
      };
    case 'REMOVE_ALERT':
      return {
        ...state,
        alert: null
      };
    default:
      return state;
  }
};

export default AppReducer;
