import React, { Fragment, useState, useEffect, useContext } from 'react';
import FadeIn from 'react-fade-in';
import Moves from '../Moves/Moves';
import Decision from '../Decisions/Decisions';
import { GlobalContext } from '../../context/GlobalState';

import { incrementScore, decrementScore } from '../../context/GlobalActions';

import { gameDecision } from '../../utils/game-decision';

const SinglePlayer = () => {
  const [userGuess, setUserGuess] = useState(null);
  const [houseGuess, setHouseGuess] = useState(null);
  const [decision, setDecision] = useState(null);
  const [moves, setMoves] = useState([]);
  const { dispatch } = useContext(GlobalContext);

  // Gets available moves.
  useEffect(() => {
    setMoves(['scissors', 'paper', 'rock']);
  }, []);

  // Set game decision and score when guesses are updated.
  useEffect(() => {
    if (userGuess !== null && houseGuess !== null) {
      if (decision === null) {
        const result = setGameDecision(userGuess, houseGuess);
        setDecision(result);
      }
      if (decision !== 'Draw.' && decision !== null) {
        if (decision === 'You Win!') {
          dispatch(incrementScore());
        } else if (decision === 'You Lose.') {
          dispatch(decrementScore());
        }
      }
    }
  }, [userGuess, houseGuess, decision, dispatch]);

  // Sets users guess and starts getting house guess.
  const setUserGuessStart = guess => {
    setUserGuess(guess);
    setHouseGuessStart();
  };

  // Gets random guess from available moves then set game decision.
  const setHouseGuessStart = () => {
    const houseDecisionIndex = Math.floor(Math.random() * moves.length);
    setHouseGuess(moves[houseDecisionIndex]);
    setGameDecision(userGuess, houseGuess);
  };

  // Decides whether player wins or loses and sets score.
  const setGameDecision = (userGuess, houseGuess) => {
    return gameDecision(userGuess, houseGuess);
  };

  // Clears userGuess & houseGuess state.
  const resetGame = () => {
    setUserGuess(null);
    setHouseGuess(null);
    setDecision(null);
  };

  return (
    <Fragment>
      {userGuess === null ? (
        <FadeIn>
          <Moves setUserGuessStart={setUserGuessStart} moves={moves} />
        </FadeIn>
      ) : (
        <React.Fragment>
          <FadeIn>
            <Decision
              resetGame={resetGame}
              userGuess={userGuess}
              houseGuess={houseGuess}
              decision={decision}
            />
          </FadeIn>
        </React.Fragment>
      )}
    </Fragment>
  );
};

export default SinglePlayer;
