import React, { useContext } from 'react';
import FadeIn from 'react-fade-in';
import { withRouter } from 'react-router-dom';

import { GlobalContext } from '../../context/GlobalState';
import {
  setShowSinglePlayer,
  setShowMultiPlayerWait,
  setAlert
} from '../../context/GlobalActions';

import { makeId } from '../../utils/room-generator';

import CustomButton from '../CustomButton/CustomButton';

import './MainMenu.scss';

const MainMenu = ({ history }) => {
  const { dispatch } = useContext(GlobalContext);
  const roomNumber = makeId(20);

  const handleSinglePlayer = () => {
    dispatch(setAlert({ status: 'regular', message: `Let's Play!` }));
    dispatch(setShowSinglePlayer());
  };

  return (
    <FadeIn>
      <section className="main-menu">
        <h3>Select game mode.</h3>
        <CustomButton onClick={handleSinglePlayer}>Single Player</CustomButton>
        <CustomButton
          onClick={() => {
            dispatch(setShowMultiPlayerWait());
            history.push(`/r/${roomNumber}`);
          }}
        >
          Multi-Player (Beta)
        </CustomButton>
      </section>
    </FadeIn>
  );
};

export default withRouter(MainMenu);
