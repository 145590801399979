import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { GlobalContext } from '../../context/GlobalState';
import { setShowMenu } from '../../context/GlobalActions';

import './Logo.scss';

const Logo = ({ history }) => {
  const { dispatch } = useContext(GlobalContext);
  return (
    <div
      className="logo-container"
      onClick={() => {
        dispatch(setShowMenu());
        history.push('/');
      }}
    >
      <h1 className="logo">Scissor.</h1>
      <h1 className="logo">Paper.</h1>
      <h1 className="logo">Rock.</h1>
    </div>
  );
};

export default withRouter(Logo);
