import React from 'react';

import './Theme.scss';

const Theme = ({ switchTheme, theme }) => {
  return (
    <div className="theme">
      {theme === 'light' ? (
        <i onClick={switchTheme} className="fas fa-moon"></i>
      ) : (
        <i onClick={switchTheme} className="fas fa-sun"></i>
      )}
    </div>
  );
};

export default Theme;
