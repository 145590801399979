import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  lazy,
  Suspense,
} from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CustomButton from './components/CustomButton/CustomButton';
import Rules from './components/Rules/Rules';
import Theme from './components/Theme/Theme';
import SinglePlayer from './components/SinglePlayer/SinglePlayer';
import MainMenu from './components/MainMenu/MainMenu';
import Alert from './components/Alert/Alert';

import { GlobalContext } from './context/GlobalState';

import './App.scss';

const MultiPlayer = lazy(() => import('./components/MultiPlayer/MultiPlayer'));

function App() {
  const [toggleRules, setToggleRules] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [theme, setTheme] = useState('light');
  const { showMenu, showSinglePlayer, alert } = useContext(GlobalContext);

  // Get theme from local storage if there is one available.
  useEffect(() => {
    if (localStorage.getItem('theme')) {
      setTheme(localStorage.getItem('theme'));
    }
  }, []);

  // Changes theme and store new theme to local storage if updated.
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Toggles rules modal.
  const handleRules = () => {
    if (toggleRules === true) {
      setTimeout(() => setShowRules(!showRules), 400);
    } else {
      setShowRules(!showRules);
    }
    setToggleRules(!toggleRules);
  };

  // Sets App Theme
  const switchTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <Fragment>
      {alert ? <Alert /> : null}
      <div className="main-container">
        <Header />
        {showMenu ? <MainMenu /> : null}
        {showSinglePlayer ? <SinglePlayer /> : null}
        <Switch>
          <Suspense fallback={''}>
            <Route path="/r/:roomId" component={MultiPlayer} />
          </Suspense>
        </Switch>
        <CustomButton onClick={handleRules} rulesBtn>
          Rules
        </CustomButton>
        {showRules ? (
          <Rules handleRules={handleRules} toggleRules={toggleRules} />
        ) : null}
      </div>
      <Footer>
        <Theme switchTheme={switchTheme} theme={theme} />
      </Footer>
    </Fragment>
  );
}

export default App;
